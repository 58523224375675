
export interface Message {
  type: MessageType;
  code: string;
  parameters?: {};
  action?: () => void | null;
  actionText?: string;
}

export enum MessageType {
  OK = 'ok' as any,
  INFO = 'info' as any,
  WARNING = 'warning' as any,
  ERROR = 'error' as any
}
