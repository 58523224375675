import {
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';

import { AbstractControlComponent } from '../abstract-control-component';

export interface SelectOption {
  value: string;
  description?: string;
}

@Component({
  selector: 'st-select',
  templateUrl: './select.component.html',
  styleUrls: [
    '../abstract-control-component.scss',
    './select.component.scss',
  ],
})
export class SelectComponent extends AbstractControlComponent<string> {
  @Input() label!: string;
  @Input() placeholder = '';
  @Input() showError = true;
  @Input() options!: string[];
  @Input() translationPrefix: string = '';
  @Input() optionsAsObjects!: SelectOption[];

  @ViewChild('select', { static: true })
  select!: ElementRef<HTMLInputElement>;

  override ngOnInit() {
    super.ngOnInit();
    if (this.autofocus === '' && this.formControl.enabled) {
      this.select.nativeElement.focus();
    }
  }
}
