export interface POSId {
  operator: string;
  code: string;
}

export interface POSModel extends POSId {
  available?: boolean;
  province?: string;
  city: string;
  district?: string;
  street: string;
  postalCode: string;
  description?: string;
  openingHoursMap?: { [key: string]: { from: string, to: string } };
  latitude?: number;
  longitude?: number;
  brand?: string;
  brandPretty?: string;
  operatorPretty?: string;
  cod?: boolean;
}
