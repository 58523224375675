import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { ConfigService } from 'src/app/service';
import { Configuration } from 'src/app/shared/model/configuration.model';

@Component({
  selector: 'customer-data',
  templateUrl: './customer-data.component.html',
  styleUrls: ['./customer-data.component.scss']
})
export class CustomerDataComponent {

  @Input('translationNamespace') translationNamespace: string = 'CUSTOMER';

  @Input() showPointSelect: boolean = false;

  config$: Observable<Configuration>;

  constructor(
    public controlContainer: ControlContainer,
    private configService: ConfigService
  ) {
    this.config$ = this.configService.config$;
  }

  get namespacedCustomerNameField() : string {
    return 'FORM.ADDRESS_DATA.' + this.translationNamespace + '.NAME';
  }

  get namespacedCustomerPointCodeField() : string {
    return 'FORM.ADDRESS_DATA.' + this.translationNamespace + '.POINT_CODE';
  }

  getNamespacedCustomerFieldName(fieldName: string) : string {
    return this.translationNamespace.toLowerCase() + fieldName;
  }

  get customerFormControls() : any {
    let control = this.controlContainer.control as FormGroup;
    return control.controls;
  }
}
