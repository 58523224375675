<dimmer
  *ngIf="!currentOrderStatus"
  loadingMessage="{{ 'CONFIRMATION.LOADING_MESSAGE' | translate }}"
></dimmer>

<ng-container *ngIf="currentOrderStatus">

  <progressbar>
  </progressbar>

  <h1>{{ "STATUSES_HEADERS." + currentOrderStatus | uppercase | translate }}</h1>

  <ng-container *ngIf="isProcessingOrder()">
  <section>
    <div class="refreshing progress"></div> 
    <p *ngIf="isLabelless(); else withLabel"></p>
    <ng-template #withLabel><p class="sub-hint">{{ 'CONFIRMATION.ADVISING_MESSAGE_LABELLESS' | translate }}</p></ng-template>

  </section>
  </ng-container>

  <ng-container *ngIf="isPaymentError()">
    <section class="grid payment-options">
      <div class="refreshing fail"></div> 
      <p class="sub-hint">{{ 'CONFIRMATION.PAYMENT_ERROR_MESSAGE' | translate }}</p>
      <div 
        *ngIf="paymentRetriesCount > 0"
        class="sub-hint error-box"
      >
        {{ 'CONFIRMATION.PAYMENT_RETRY_ERROR_PT1' | translate }}
        <br/>
        {{ 'CONFIRMATION.PAYMENT_RETRY_ERROR_PT2' | translate }}
        <section class="payment-error error-block alt">
          <span class="pictogram email-pictogram">biuro@bliskapaczka.pl</span>
          <span class="pictogram phone-pictogram">{{ 'CONFIRMATION.CALL_CENTER_INFORMATION' | translate }}</span>
        </section>
      </div>
      <div class="row align-center">
        <button 
          data-test="confirmation-retry-payment"
          class="col col-8 col-md-12 retry-payment" 
          [ngClass]="isPaymentRetryProcessing ? 'disabled' : ''"
          (click)="isPaymentRetryProcessing || retryPayment()"
        >
          {{ 'CONFIRMATION.RETRY_PAYMENT' | translate }}
        </button>   
      </div>

    </section>
  </ng-container>

  <ng-container *ngIf="isOrderReady()">
    <section class="grid ready-to-send grid">
      <div class="refreshing success"></div> 
      <h2 data-test="header-success">{{ 'CONFIRMATION.POST_ADVICE_STEPS' | translate }}</h2>
      <ol class="todo-list">
        <li *ngIf="!isLabelless()">{{ 'CONFIRMATION.POST_ADVICE_STEP1' | translate }} <a class="text-link" (click)="getDocuments()">{{ 'CONFIRMATION.WAYBILL' | translate }}</a>.</li>
        <li>{{ 'CONFIRMATION.POST_ADVICE_STEP2' | translate }}</li>
        <li>{{ 'CONFIRMATION.POST_ADVICE_STEP3' | translate }} <a class="text-link" [attr.href]="frontUrl + packagingHintPath" target="_blank">{{ 'CONFIRMATION.PACKING_GUIDE' | translate }}</a>.</li>
        <li>{{ 'CONFIRMATION.POST_ADVICE_STEP4' | translate }}</li>
        <li *ngIf="!isLabelless()">{{ 'CONFIRMATION.POST_ADVICE_STEP5' | translate }}</li>
        <li>{{ 'CONFIRMATION.POST_ADVICE_STEP6' | translate }}<br/>
          <span class="point-address">{{ "OPERATORS." + savedOrder.operatorName | uppercase | translate }} / {{ savedOrder.postingCode }}, {{ savedOrder.postingStreet }}, {{ savedOrder.postingPostCode }} {{ savedOrder.postingCity }}</span><br/>      
          <ng-container *ngIf="isOrderPostingPointBound(); else anyPoint"><span class="bound-point">{{ 'CONFIRMATION.POST_ADVICE_STEP6_BOUND_POINT' | translate }}</span></ng-container>     
          <ng-template #anyPoint><span class="any-point">{{ 'CONFIRMATION.POST_ADVICE_STEP6_ANY_POINT' | translate }}</span></ng-template>
        </li>
      </ol>
      <div *ngIf="!isLabelless()" class="row align-center">
        <button 
          data-test="confirmation-get-waybill"
          class="col col-12 get-documents" 
          (click)="getDocuments()"
          >
          {{ 'CONFIRMATION.GET_PARCEL_DOCUMENTS' | translate }}
        </button>
      </div>
      <div class="row align-center">
        <button 
          data-test="confirmation-get-protocol"
          class="secondary col col-12 get-protocol" 
          (click)="getProtocol()"
          >
          {{ 'CONFIRMATION.GET_RETURN_PROTOCOL' | translate }}
        </button>
      </div>
    </section>
  </ng-container>

  <ng-container *ngIf="isAdvisingError()">
    <section class="advising-error">
      <div class="refreshing fail"></div> 
      <p class="sub-hint">{{ 'CONFIRMATION.ADVISING_ERROR_MESSAGE_PT1' | translate }}</p>
      <p class="sub-hint">{{ 'CONFIRMATION.ADVISING_ERROR_MESSAGE_PT2' | translate }}</p>
    </section>
    <section class="advice-error error-block alt">
      <span class="pictogram email-pictogram">biuro@bliskapaczka.pl</span>
      <span class="pictogram phone-pictogram">{{ 'CONFIRMATION.CALL_CENTER_INFORMATION' | translate }}</span>
    </section>
  </ng-container>

  <section>
    <h2 data-test="header-order-details">{{ "CONFIRMATION.ORDER_DETAILS" | translate }}</h2>
    <div class="order-data">
      <p data-test="order-data-sender-name"><span class="field">{{ "CONFIRMATION.SENDER" | translate }}: </span><span class="value">{{ savedOrder.senderFirstName + ' ' + savedOrder.senderLastName }}</span></p>
      <p data-test="order-data-receiver-name"><span class="field">{{ "CONFIRMATION.RECEIVER" | translate }}: </span><span class="value">{{ savedOrder.receiverCompanyName }}</span></p>
      <p data-test="order-data-receiver-address"><span class="field">{{ "CONFIRMATION.DESTINATION" | translate }}: </span><span class="value">{{ 
        savedOrder.receiverStreet 
        + ' ' + savedOrder.receiverBuildingNumber
        + ( savedOrder.receiverFlatNumber ? ('/' + savedOrder.receiverFlatNumber) : '' )
        + ', ' + savedOrder.receiverPostCode
        + ' ' + savedOrder.receiverCity
      }}</span></p>
      <p data-test="order-data-number"><span class="field">{{ "CONFIRMATION.ORDER_NUMBER" | translate }}: </span><span class="value">{{ savedOrder.number }}</span></p>
      <p data-test="order-data-operator"><span class="field">{{ "CONFIRMATION.OPERATOR" | translate }}: </span><span class="value">{{ "OPERATORS." + savedOrder.operatorName | uppercase | translate }}</span></p>
      <p data-test="order-data-posting-point"><span class="field">{{ "CONFIRMATION.DROPOFF_POINT" | translate }}: </span><span class="value">{{ savedOrder.postingCode }}</span></p>
    </div>
  </section>

</ng-container>
