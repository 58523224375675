import { Injectable } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormControlOptions,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { BehaviorSubject, of, Observable } from 'rxjs';
import { CustomValidators } from '../forms/validator';
import { OperatorSelection } from '../shared/model/operator-selection.model';
import { DeliveryType, OrderAdditionalService, ValidationAdditionalService } from '../shared/model/price.model';

import { Order, ProductItem } from '../shared/model/order.model';
import { ProductItemValues } from '../forms/model/form-controls.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ReturnFormService {

  form!: FormGroup;
  _form$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  form$: Observable<any> = this._form$.asObservable().pipe();

  operatorSelection: OperatorSelection = {};
  _operatorSelection$: BehaviorSubject<OperatorSelection> = new BehaviorSubject<any>(null);
  operatorSelection$: Observable<OperatorSelection> = this._operatorSelection$.asObservable().pipe();

  private storedPoints: any = {};

  constructor(
    private formBuilder: FormBuilder,
    private translateService: TranslateService
  ) {
  }

  setOperatorSelect(selection: OperatorSelection) {
    this.operatorSelection = selection;
    this.storedPoints[selection.operator+'-'+selection.deliveryType] = selection.posId;
    this._operatorSelection$.next(this.operatorSelection);
  }

  loadForm$(): Observable<any> {
    this.form = new FormGroup({
      product: this.createReturnedProductForm(),
      parcel: this.createReturnParcelForm(),
      sender: this.createLocationForm(true),
      seller: this.createLocationForm(false),
      terms: new FormControl(null, [ Validators.requiredTrue ] as ValidatorFn[]),
      labelless: new FormControl(null),
      trackingReturnUrl: new FormControl(),
    })
    this._form$.next(this.form);
    return of(this.form).pipe();
  }

  markFormAsTouched(form: FormGroup) {
    form.markAllAsTouched();
    this._form$.next(form);
  }

  // needed or formArrayName to work
  get productItems(): FormArray{
    return <FormArray> this.form.get('product.productItems');
  }

  public getStoredPoint(operator: string, delivery: DeliveryType) : string|undefined {
    return this.storedPoints[operator+'-'+delivery] || undefined;
  }

  public addProductItem(): number {
    const currentItems = this.productItems.length;
    this.productItems.push(this.createProductItemForm(currentItems));
    return currentItems;
  }

  public deleteProductItem(id: number): void {
    const index = this.productItems.controls.findIndex(idx => {
      return idx.value.itemId == id
    });
    if (index !== -1) this.productItems.removeAt(index);
  }

  public get orderAdditionalServices(): OrderAdditionalService[] {
    const additionalServices: OrderAdditionalService[] = [];
    if (this.form.controls['labelless'].value) {
      additionalServices.push({value: null, name: 'LABELLESS'})
    }
    return additionalServices;
  }

  public get validationAdditionalServices(): ValidationAdditionalService[] {
    const additionalServices: ValidationAdditionalService[] = [];
    if (this.form.controls['labelless'].value) {
      additionalServices.push({value: null, type: 'LABELLESS'})
    }
    return additionalServices;
  }


  public remapFormToModel(): Order {
    const formValues = this.form.value;

    return {
      returnConfiguration: {
        returnShopOrderNumber: formValues.product.shopOrderNumber,
        returnReason: this.translateService.instant('RETURN_REASONS.'+formValues.product.returnReason),
        returnReasonAdditionalInfo: formValues.product.returnReasonAdditionalInfo,
        returnItems: this.mapReturnItemFormToModel(formValues.product.productItems),
      },

      parcels: [{
        dimensions: formValues.parcel.dimensions,
        insuranceValue: formValues.parcel.insuranceValue,
      }],
      operatorName: formValues.parcel.operatorName,
      postingCode: formValues.parcel.dropoffPoint,
      deliveryType: formValues.parcel.deliveryType,
      additionalServices: this.orderAdditionalServices,
      senderFirstName: formValues.sender.name.split(' ')[0],
      senderLastName: formValues.sender.name.split(' ').splice(1).join(' '),
      senderEmail: formValues.sender.email,
      senderBuildingNumber: formValues.sender.buildingNumber,
      senderFlatNumber: formValues.sender.flatNumber,
      senderStreet: formValues.sender.street,
      senderCity: formValues.sender.city,
      senderPhoneNumber: formValues.sender.phoneNumber,
      senderPostCode: formValues.sender.postCode,

      receiverFirstName: undefined,
      receiverLastName: undefined,
      receiverCompanyName: formValues.seller.name,
      receiverEmail: formValues.seller.email,
      receiverBuildingNumber: formValues.seller.buildingNumber,
      receiverFlatNumber: formValues.seller.flatNumber,
      receiverStreet: formValues.seller.street,
      receiverCity: formValues.seller.city,
      receiverPhoneNumber: formValues.seller.phoneNumber,
      receiverPostCode: formValues.seller.postCode,
      trackingReturnUrl: formValues.trackingReturnUrl
    }
  }

  private mapReturnItemFormToModel(formItems: ProductItemValues[]): ProductItem[] {
    return formItems
      .map(c => {
        return {
          itemName: c.description,
          itemCount: c.quantity,
          itemPrice: c.unitPrice
        }
      });
  }

  private createReturnedProductForm() {
    return new FormGroup({
      shopOrderNumber: new FormControl(null,
        [
          Validators.required,
          CustomValidators.bpmaxlength(64)
        ] as ValidatorFn[]
      ),
      productItems: this.formBuilder.array(
        [this.createProductItemForm(0)],
        [
          Validators.required
        ] as ValidatorFn[]
      ),
      returnReason: new FormControl(null, [
        Validators.required
      ] as ValidatorFn[]),
      returnReasonAdditionalInfo: new FormControl(null, [
        CustomValidators.bpmaxlength(255)
      ] as ValidatorFn[])
    });
  }

  private createProductItemForm(id: number): FormGroup {
    return this.formBuilder.group({
        description: new FormControl(null,
          [
            Validators.required,
            CustomValidators.bpmaxlength(99)
          ] as ValidatorFn[]
        ),
        quantity: new FormControl(null,
          [
            Validators.required,
            Validators.min(1),
            CustomValidators.number,
            CustomValidators.bpmaxlength(3)
          ] as ValidatorFn[]
        ),
        unitPrice: new FormControl(null,
          [
            Validators.required,
            Validators.min(0.01),
            CustomValidators.number,
            CustomValidators.bpmaxlength(10)
          ] as ValidatorFn[]
        ),
        itemId: new FormControl(id,
          [] as ValidatorFn[]
        )
      })
  }

  private createReturnParcelForm() {
    return new FormGroup({
      operatorName: new FormControl(null, [
        Validators.required,
      ] as ValidatorFn[]),
      dimensions: new FormGroup({
        length: new FormControl(null, [
          Validators.required,
          Validators.min(1),
          CustomValidators.number,
          CustomValidators.bpmaxlength(7)
        ] as ValidatorFn[]),
        width: new FormControl(null, [
          Validators.required,
          Validators.min(1),
          CustomValidators.number,
          CustomValidators.bpmaxlength(7)
        ] as ValidatorFn[]),
        height: new FormControl(null, [
          Validators.required,
          Validators.min(1),
          CustomValidators.number,
          CustomValidators.bpmaxlength(7)
        ] as ValidatorFn[]),
        weight: new FormControl(null, [
          Validators.required,
          Validators.min(0.1),
          CustomValidators.number,
          CustomValidators.bpmaxlength(7)
        ] as ValidatorFn[]),
      }),
      insuranceValue: new FormControl(null, [
        CustomValidators.number,
        Validators.min(1),
        CustomValidators.bpmaxlength(7)
      ] as ValidatorFn[]),
      deliveryType: new FormControl(null),
      dropoffPoint: new FormControl(null, [
        Validators.required,
      ] as ValidatorFn[])
    });
  }

  private createLocationForm(isSender: boolean) {
    const nameValidators: ValidatorFn[] = [
      Validators.required,
      CustomValidators.bpmaxlength(50)
    ] as ValidatorFn[];

    if (isSender) {
      nameValidators.push(CustomValidators.nameWithLastname);
    }

    return new FormGroup({
      name: new FormControl(null, nameValidators),
      street: new FormControl(null, [
        Validators.required,
        CustomValidators.bpmaxlength(30)
      ] as ValidatorFn[]),
      buildingNumber: new FormControl(null, [
        Validators.required,
        CustomValidators.bpmaxlength(5)
      ] as ValidatorFn[]),
      flatNumber: new FormControl(null, [
        CustomValidators.bpmaxlength(5)
      ] as ValidatorFn[]),
      postCode: new FormControl(null, [
        Validators.required,
        CustomValidators.postcode
      ] as ValidatorFn[]),
      city: new FormControl(null, [
        Validators.required,
        CustomValidators.bpmaxlength(50)
      ] as ValidatorFn[]),
      phoneNumber: new FormControl(null, [
        Validators.required,
        CustomValidators.specialMobilePhone
      ] as ValidatorFn[]),
      email: new FormControl(null, [
        Validators.required,
        CustomValidators.email
      ] as ValidatorFn[])
    });
  }

}
