<dimmer
  *ngIf="isOrderProcessing"
  loadingMessage="{{ 'FORM.ORDER_PROCESSING' | translate }}"
></dimmer>

<ng-container *ngIf="configurationReady">
  <main-header></main-header>

  <form
    class="main-form"
    [formGroup]="mainForm"
    (submit)="onSubmit()"
  >
    <section
      id="return-form-info"
      class="grid"
      *ngIf="showReturnForm"
    >
      <h2>{{ 'FORM.RETURN_FORM.HEADER' | translate }}</h2>
      <div class="row">
        <st-input
          [control]="productFormControls.shopOrderNumber"
          label="{{ 'FORM.RETURN_FORM.ORDER_NR_LABEL' | translate }}"
          hintText="{{ 'FORM.RETURN_FORM.ORDER_NR_HINT' | translate }}"
          isRequired="true"
          name="shopOrderNumber"
          data-test="shopOrderNumber"
          class="col-11 col-md-16">
        </st-input>
      </div>

      <product-items
        formGroupName="product"
        [form]="productItems"
      >
      </product-items>

      <div class="row">
        <st-select
          [control]="productFormControls.returnReason"
          label="{{ 'FORM.RETURN_FORM.RETURN_REASON' | translate }}"
          name="returnReason"
          data-test="returnReason"
          isRequired="true"
          class="col-11 col-md-16"
          translationPrefix="RETURN_REASONS."
          [optionsAsObjects]="serviceConfig.returnReasons"
        >
        </st-select>
      </div>

      <div class="row">
        <st-textarea
          [control]="productFormControls.returnReasonAdditionalInfo"
          label="{{ 'FORM.RETURN_FORM.ADDITIONAL_INFO_LABEL' | translate }}"
          name="returnAdditionalInfo"
          data-test="returnAdditionalInfo"
          class="col-11 col-md-16"
        >
        </st-textarea>
      </div>
    </section>

    <section
      id="return-parcel-info"
      class="grid"
    >
      <h2>{{ 'FORM.RETURN_PARCEL.PARCEL_HEADER' | translate }}</h2>
      <p class="sub-hint">{{ 'FORM.RETURN_PARCEL.PACKAGING_HINT_PT1' | translate }} <a [attr.href]="frontUrl + packagingHintPath" target="_blank">{{
          'FORM.RETURN_PARCEL.PACKAGING_HINT_PT2' | translate }}</a> {{ 'FORM.RETURN_PARCEL.PACKAGING_HINT_PT3' |
        translate }}</p>

      <parcel-dimensions
        [form]="parcelDimensionsForm"
        (changeEvent)="onParcelParametersChange()"
      >
      </parcel-dimensions>

      <div class="row">
        <st-input
          *ngIf="showInsurance"
          [control]="parcelFormControls.insuranceValue"
          label="{{ 'FORM.RETURN_PARCEL.INSURANCE_LABEL' | translate }}"
          [isNumber]=true
          [min]="0.01"
          [maxlength]=7
          backdropText="zł"
          name="insuranceValue"
          data-test="insuranceValue"
          class="col-5"
          (change)="onParcelParametersChange()"
        >
        </st-input>
      </div>
      <div class="row">
        <st-checkbox
          name="labelless"
          data-test="labelless"
          [control]="mainFormControls.labelless"
          (change)="onParcelParametersChange()"
        >
          <span>
            {{ 'LABELLESS' | translate }}
          </span>
        </st-checkbox>
      </div>
    </section>

    <section id="return-operator-info">
      <span
        class="updating-prices"
        *ngIf="updatingPrices"
      >
        {{ 'FORM.PROCESSING_PRICE_REQUEST' | translate }}
      </span>
      <h2>{{ 'FORM.RETURN_PARCEL.OPERATOR_HEADER' | translate }}</h2>
      <p class="sub-hint">
        {{ 'FORM.RETURN_PARCEL.OPERATOR_HINT_PT1' | translate }}
        <br />
        {{ 'FORM.RETURN_PARCEL.OPERATOR_HINT_PT2' | translate }}
      </p>

      <div
        class="error"
        *ngIf="showOperatorSelectError()"
      >
        {{ 'VALIDATION_MESSAGES.postingPoint' | translate }}
      </div>
      <div
        class="error"
        *ngIf="showNoOfferError()"
      >
        {{ 'FORM.NO_OFFERS' | translate }}
      </div>
      <div
        class="error"
        *ngIf="pricingError"
      >
        {{ 'FORM.ERROR_OFFERS' | translate }}
      </div>
      <div
        class="exclamation"
        *ngIf="showInitialOfferExplanation()"
      >
        {{ 'FORM.NO_INITIAL_OFFER' | translate }}
      </div>

      <ng-container *ngFor="let offer of pricing$|async">
        <operator-select
          *ngIf="allowedOperators.includes(offer.operatorName)"
          name="{{offer.operatorName}}"
          delivery="{{offer.deliveryType}}"
          price="{{offer.price?.gross}}"
          [errors]="offer.unavailableReasons"
          [enabled]="isOperatorOfferEnabled(offer.operatorName, offer.deliveryType)"
          (click)="selectService(offer.operatorName, offer.deliveryType)"
          class="price-tooltip-wrapper tooltip-right"
        >
          <ng-container
            *ngIf="isOperatorOfferEnabled(offer.operatorName, offer.deliveryType)"
            [ngTemplateOutlet]="pricingDetails"
            [ngTemplateOutletContext]="{ pricingDetails: findOffer(offer.operatorName, offer.deliveryType) }"
          ></ng-container>
        </operator-select>
      </ng-container>

    </section>

    <section
      id="return-sender-info"
      class="grid"
    >
      <h2>{{ 'FORM.ADDRESS_DATA.SENDER_HEADER' | translate }}</h2>
      <customer-data
        formGroupName="sender"
        translationNamespace="SENDER"
      ></customer-data>
    </section>

    <section id="return-seller-info" class="grid">
      <h2>{{ 'FORM.ADDRESS_DATA.SELLER_HEADER' | translate }}</h2>
      <p class="sub-hint">{{disableReceiverFields ? ('FORM.ADDRESS_DATA.SELLER_HEADER_HINT_DISABLED_SECTION' | translate: { partnerName: partnerName }) : 'FORM.ADDRESS_DATA.SELLER_HEADER_HINT' | translate }}</p>
      <customer-data
        formGroupName="seller"
        translationNamespace="SELLER"
        [showPointSelect]="selectedOperator.deliveryType === DeliveryType.P2P"
      ></customer-data>
    </section>

    <section class="grid final-step">
      <h2>{{ 'FORM.SUMMARY.SUMMARY_HEADER' | translate }}</h2>
      <p *ngIf="showReturnForm" class="sub-hint">{{ 'FORM.FINAL_RETURN_HINT' | translate }}</p>
      <p class="sub-hint">{{ 'FORM.ALSENDO_FOOTPRINT' | translate: { partnerName: partnerName } }}</p>
      <p class="regulations">
        <st-checkbox
          name="regulations"
          data-test="regulations"
          [control]="mainFormControls.terms"
        >
          <span>
            {{ 'FORM.I_ACCEPT' | translate }}
            <a class="link" [attr.href]="frontUrl + termsPath" target="_blank">{{ 'FORM.TERMS' | translate }}</a>
            {{ 'FORM.AND' | translate }}
            <a class="link" [attr.href]="frontUrl + privatePolicyPath" target="_blank">{{ 'FORM.PRIVACY_POLICY' | translate }}</a>.
          </span>
        </st-checkbox>
      </p>

      <div class="row payment-info price-tooltip-wrapper tooltip-left">
        <ng-container *ngIf="!!currentOfferPrice; else noPrice">
          <span>{{ 'FORM.TO_PAY' | translate }}: <span class="payment-price">{{ currentOfferPrice.gross | number:'1.2-2' }} zł</span> ({{ currentOfferPrice.net | number:'1.2-2' }} zł netto) </span>
          <ng-container
            [ngTemplateOutlet]="pricingDetails"
            [ngTemplateOutletContext]="{ pricingDetails: currentOffer }"
          ></ng-container>
        </ng-container>
        <ng-template #noPrice>
          <span>{{ 'FORM.NO_OFFER_SELECTED' | translate }}</span>
        </ng-template>
      </div>
      <div class="row">
        <button
          class="col col-8 col-md-16 submit-form"
          data-test="submitFormButton"
          type="submit"
        >
          {{ (showReturnForm ? 'FORM.SUBMIT_RETURN' : 'FORM.SUBMIT') | translate }}
        </button>
        <message-panel
          *ngIf="processingMessages.length && !mainForm.valid"
          [messages]="processingMessages"
        ></message-panel>
      </div>
    </section>

  </form>
</ng-container>


<ng-template #dialogRef>
  <div
    id="bpWidget"
    [ngStyle]="isMobile ? {'width': '95vw', 'height': '95vh'} : {'width': '720px', 'height': '720px'}"
  >
  </div>
</ng-template>

<ng-template #pricingDetails let-pricingDetails="pricingDetails">
  <div class="price-tooltip">
    <ul class="pricing-details">
      <li>{{ 'FORM.PRICING_DETAILS.PARCEL' | translate }}: {{ pricingDetails?.parcel?.price?.gross | number:'1.2-2' }} zł</li>
      <li *ngFor="let additionalService of pricingDetails?.additionalServices">{{ 'FORM.PRICING_DETAILS.' + additionalService.serviceName | translate }}: {{ additionalService.price.gross | number:'1.2-2' }} zł</li>
    </ul>
  </div>
</ng-template>
