<!-- TODO: dynamic switching mechanism -->
<div
  id="header"
  class="wrapper header"
>
  <img
    class="logo"
    data-test="header-logo"
    src="/assets/images/alsendo_logo.svg"
    alt="{{'PRODUCT_NAME' | translate}}"
  >
  <h2>{{(config$ | async)?.setup?.header?.title}}</h2>
  <img
    class="logo"
    data-test="header-client-logo"
    [src]="(config$ | async)?.branding?.logoUrl"
    alt="{{'PRODUCT_NAME' | translate}}"
  >
</div>
