<label>
  <div class="checkbox-block">
    <input
      type="checkbox"
      [formControl]="formControl"
      (change)="onChange($event)"
    />
    <span
      class="checkbox"
      (blur)="onBlur($event)"
      attr.data-test="{{ dataTest }}"
    >
    </span>
    <span class="checkbox-desc">
      <ng-content></ng-content>
    </span>
  </div>

  <form-errors
    *ngIf="isErrorVisible"
    [control]="formControl"
  ></form-errors>
</label>
  