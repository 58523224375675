import { CountryScopedOffer, DeliveryType, Price } from "./price.model"

export interface ApiConfig {
    deliveryOptions: [ParcelOperator],
    offer: [CountryScopedOffer],
    returnReasons: [any]
}

export interface ParcelOperator {
    operatorName: string,
    deliveryType: DeliveryType,
    price?: Price | null,
    available?: boolean,
    unavailableReasons?: any[];
}
