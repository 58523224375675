
<div class="product-items">    

  <div class="row item-row" *ngFor="let control of formArray.controls; let i = index">
    <product-item 
      formGroupName="control"
      [form]="getItemFormControls(i)"
      [itemId]=i  
      [cancellable]=" (formArray.controls.length > 1) ? true : false "
    ></product-item>
  </div>

  <div class="row">
    <span
      class="add-item tertiary"
      data-test="add-product-item"
      (click)="addProductItem()"
    >{{ 'FORM.RETURN_FORM.ADD_PRODUCT' | translate }}</span>
  </div>

</div>
