import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ConfigService, PlatformService, ScriptService, ScriptTargets } from './service';
import { GtmService } from './service/gtm.service';
import { debounceTime, fromEvent, merge, throttleTime } from 'rxjs';

declare global {
  interface Window {
    _hjSettings: {};
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  customHeader: boolean = false;
  @ViewChild('customHeader', { static: false }) customHeaderElement!: ElementRef;
  @ViewChild('appWrapper', { static: false }) wrapperElement!: ElementRef;

  constructor(
    private translateService: TranslateService,
    private configService: ConfigService,
    private scriptService: ScriptService,
    private platformService: PlatformService,
    private gtmService: GtmService,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.translateService.addLangs(['pl']);
    this.translateService.setDefaultLang('pl');
    this.translateService.use('pl');
    if (this.platformService.isBrowser()) {
      this.gtmService.init();
    }
  }

  ngAfterViewInit(): void {
    if (this.platformService.isServer()) {
      return;
    }

    this.configService.config$.subscribe(config => {
      this.customHeader = !!config.customProperties;
      if (config.customProperties?.customHeaderStylesUrl) {
        this.scriptService.loadStyles(config.customProperties.customHeaderStylesUrl);
      }
      if (config.customProperties?.customHeaderScriptUrl) {
        this.scriptService.loadScript(config.customProperties.customHeaderScriptUrl, 'module');
      }
      if (config.customProperties?.customFaviconUrl) {
        document.getElementById("favicon")?.setAttribute("href", config.customProperties.customFaviconUrl);
      }

      if (config?.hotjarId) {
        window._hjSettings = {
          "hjid": config.hotjarId,
          "hjsv": 6
        };
        this.scriptService.loadScript(this.getHotjarUrl(config.hotjarId, '6'), 'text/javascript', ScriptTargets.HEAD);
      }
    });

    merge(
      fromEvent(window, "resize"),
      fromEvent(window, "scroll")
    ).pipe(
      throttleTime(10),
      debounceTime(250) 
      // paypo header has 200ms animation delay on padding, 
      // thus the need to wait a bit longer to have the final value calculated correctly
    ).subscribe(
      () => this.setHeaderMargin()
    );
  }

  setHeaderMargin(): void {
    const headerElement = this.customHeaderElement?.nativeElement as HTMLElement;
    if (!!headerElement) {
      const innerHeight = (headerElement.firstChild as HTMLElement).offsetHeight;
      this.renderer.setStyle(headerElement, 'margin-bottom', innerHeight+'px');
      this.renderer.setStyle(this.wrapperElement.nativeElement, 'min-height', 'calc(100vh - '+innerHeight+'px)');
    }
  }

  private getHotjarUrl(id: string, version: string) {
    return `https://static.hotjar.com/c/hotjar-${id}.js?sv=${version}`;
  }
}
