import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dimmer',
  templateUrl: './dimmer.component.html',
  styleUrls: ['./dimmer.component.scss']
})
export class DimmerComponent implements OnInit {

  @Input() loadingMessage: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
