import { Directive, ElementRef, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';

@Directive({
  selector: 'input[number-value]',
  host: {
    '(input)': 'onInput($event.target.value)',
    '(blur)': 'onBlur()',
    '[disabled]': 'disabled'

  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberValueAccessorDirective),
      multi: true
    }
  ]
})
export class NumberValueAccessorDirective implements ControlValueAccessor {
  @Input() integersOnly!: boolean;

  get disabled() {
    return this._disabled;
  }

  protected decimalPositions: number = 2;
  private _element: HTMLInputElement;
  private _disabled?: boolean;

  constructor(elementRef: ElementRef) {
    this._element = elementRef.nativeElement;
  }

  onInput(value: string) {
    if (this.integersOnly) {
      value = value.replace(/[^\d]+/g, '');
    } else {
      value = value.replace(/[,]/g, '.');
      value = value.replace(/[^\d,.]+/g, '');
      const valueArr: string[] = value.split('.');

      if (valueArr.length >= 2) {
        valueArr[0] = valueArr[0] || '0';
        value = valueArr[0] + '.'
          + valueArr[1].substr(0, this.decimalPositions);
      }
    }

    this.rewriteValue(value);

    this.onChangeCallback(
      !_.isEmpty(value) && !_.isNaN(_.toNumber(value)) ?
        _.toNumber(value) : value
    );
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: any) {
    this._element.value = _.toString(value).replace('.', ',');
  }

  rewriteValue(value: any) {
    this.writeValue(value);
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  private onChangeCallback: (value: any) => void = () => { };

  private onTouchedCallback: () => void = () => { };
}
