import { Injectable, NgModule } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot, Routes, TitleStrategy } from '@angular/router';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { AuthGuard } from './core/auth.guard';
import { GenericErrorComponent } from './generic-error/generic-error.component';
import { MainComponent } from './main/main.component';
import { ConfigService } from './service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CustomTitleStrategy extends TitleStrategy {
  constructor(
    private readonly title: Title,
    private configService: ConfigService,
    private translateService: TranslateService
  ) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot): void {
    this.buildTitle(routerState);
  }

  override getResolvedTitleForRoute(snapshot: ActivatedRouteSnapshot): any {
    const routeTitle = snapshot.data['title'] || '';
    this.configService.config$.subscribe(config => {
      this.title.setTitle(config.customProperties?.customTitle || this.translateService.instant(routeTitle));
    });
  }
}


export const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    data: { title: 'APP_TITLES.MAIN' }
  },
  {
    path: 'unauthorized',
    component: GenericErrorComponent,
    data: { message: 'UNAUTHORIZED_MESSAGE', header: 'GENERIC_ERROR_HEADER', title: 'APP_TITLES.MAIN' },
  },
  {
    path: 'confirmation',
    data: { title: 'APP_TITLES.SUMMARY' },
    children: [
      {
        path: '',
        component: GenericErrorComponent,
        data: { message: 'NOT_FOUND_MESSAGE', header: 'GENERIC_ERROR_HEADER' }
      },
      {
        path: ':orderNumber',
        component: ConfirmationComponent,
        canActivate: [AuthGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: TitleStrategy,
      useClass: CustomTitleStrategy
    }
  ]
})
export class AppRoutingModule { }
