import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { MatDialog } from '@angular/material/dialog';
import { ReturnFormService } from "../service/return-form.service";
import { MapOptions } from '../shared/model/map-options.model';
import { OperatorSelection } from '../shared/model/operator-selection.model';
import { POSModel } from '../shared/model/pos.model';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { environment } from "../../environments/environment";
import { AuthService } from '../service/auth.service';
import { ScriptService } from '../service/script.service';
import { WindowService } from '../service/window.service';
import { ApiConfig, ParcelOperator } from '../shared/model/api-configuration.model';

import * as Controls from '../forms/model/form-controls.model';
import { ConfigService, PlatformService } from '../service';
import { ApiService } from '../service/api.service';
import { Message, MessageType } from '../shared/model/message';
import { Order } from '../shared/model/order.model';
import { DeliveryType, Price, PriceRequest, PriceResponse } from '../shared/model/price.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {
  tokenPresent: boolean = true;

  packagingHintPath = "aktualnosci/instrukcja-pakowania-przesylek";
  termsPath = "static/pdf/regulamin.pdf";
  privatePolicyPath = "static/pdf/polityka_prywatnosci.pdf";

  formsReady = false;
  configReady = false;

  mainForm!: FormGroup;
  mainFormControls!: Controls.MainFormControls;
  productFormControls!: Controls.ReturnedProductFormControls;
  productItems!: FormArray;
  productItemsControls!: FormGroup[];

  parcelForm!: FormGroup;
  parcelFormControls!: Controls.ReturnParcelFormControls;
  parcelDimensionsForm!: FormGroup;

  senderForm!: FormGroup;
  senderFormControls!: Controls.LocationFormControls;

  sellerForm!: FormGroup;
  sellerFormControls!: Controls.LocationFormControls;

  serviceConfig!: ApiConfig;

  pricing!: PriceResponse[];
  _pricing$: BehaviorSubject<ParcelOperator[]> = new BehaviorSubject<any>(null);
  pricing$: Observable<ParcelOperator[]> = this._pricing$.asObservable().pipe();
  updatingPrices = false;
  offerAvailable = false;
  pricingError = false;

  isMobile = false;
  mapLoaded = false;

  isOrderProcessing = false;
  orderProcessingError = false;
  processingMessages: Message[] = [];

  selectedOperator: OperatorSelection = {};
  operatorSelections: Array<OperatorSelection> = [];
  allowedOperators: string[] = [];
  frontUrl!: string;

  private pricingTimeout!: ReturnType<typeof setTimeout>;

  private allowedDeliveryTypes: DeliveryType[] = [];
  private mapUrl!: string;
  private subscriptions: Subscription[] = [];

  @ViewChild('dialogRef')
  dialogRef!: TemplateRef<any>;

  constructor(
    public dialog: MatDialog,
    private apiService: ApiService,
    private configService: ConfigService,
    private authService: AuthService,
    private formService: ReturnFormService,
    private platformService: PlatformService,
    private scriptService: ScriptService,
    private windowService: WindowService,
    private route: ActivatedRoute
  ) {
    this.subscriptions.push(
      this.configService.config$.subscribe(res => {
        this.mapUrl = res.mapUrl;
        this.frontUrl = res.frontUrl;
        this.allowedOperators = res.allowedOperators;
        this.allowedDeliveryTypes = res.allowedDeliveryTypes;
      }),

      this.authService.userConfig$.subscribe(resp => {
        if (resp == undefined) return;
        this.serviceConfig = resp;
        this.configReady = true;

        this._pricing$.next(resp.deliveryOptions);
      }),

      this.formService.loadForm$().subscribe(resp => {
        if (resp == undefined) return;

        this.mainForm = resp;
        this.mainFormControls = resp.controls;

        this.productFormControls = resp.controls.product.controls;
        this.productItems = resp.get('product.productItems'); // FormArray
        this.productItemsControls = this.productItems.controls as FormGroup[];
        this.parcelForm = resp.controls.parcel; // FormGroup
        this.parcelFormControls = resp.controls.parcel.controls; // Object[...FormControl]
        this.parcelDimensionsForm = resp.controls.parcel.controls.dimensions;

        this.senderForm = resp.controls.sender; // FormGroup
        this.sellerForm = resp.controls.seller; // FormGroup
        this.senderFormControls = resp.controls.sender.controls; // Object[...FormControl]
        this.sellerFormControls = resp.controls.seller.controls; // Object[...FormControl]

        this.formsReady = true;
      }),

      this.formService._operatorSelection$.subscribe((form: OperatorSelection) => {
        if (form) {
          this.parcelFormControls.operatorName?.setValue(form.operator);
          this.parcelFormControls.deliveryType?.setValue(form.deliveryType);
          this.parcelFormControls.dropoffPoint?.setValue(form.posId);
          this.selectedOperator.posId = form.posId;
        }
        if (this.parcelForm) {
          this.parcelForm.markAsTouched();
        }
        this.dialog.closeAll();
      }),
      this.route.queryParams.subscribe(params => {
        this.mainForm.controls['trackingReturnUrl'].setValue(params['trackingReturnUrl']);
        this.senderForm.controls['name'].setValue(params['senderName']);
        this.senderForm.controls['email'].setValue(params['senderEmail']);
        this.senderForm.controls['phoneNumber'].setValue(params['senderPhoneNumber']);
      })
    )
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.windowService.isMobile$.subscribe(isMobile => this.isMobile = isMobile)
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  showOperatorSelectError(): boolean {
    return this.parcelFormControls.operatorName.touched &&
      !this.parcelFormControls.operatorName.value &&
      !this.parcelFormControls.deliveryType?.value &&
      !this.parcelFormControls.dropoffPoint?.value;
  }

  showInitialOfferExplanation(): boolean {
    return !this.pricing && !this.pricingError;
  }

  showNoOfferError(): boolean {
    return !!this.pricing && !this.isOfferAvailable();
  }

  isOperatorOfferEnabled(operator: string, delivery: DeliveryType) {
    return this.pricing?.some(item =>
      item.available && item.operatorName === operator && item.deliveryType === delivery
    ) || false;
  }

  isOfferAvailable(): boolean {
    return this.pricing && this.pricing?.some(item => item.available) || false;
  }

  onParcelParametersChange() {
    if (!this.parcelDimensionsForm.valid) {
      return;
    }

    clearTimeout(this.pricingTimeout);
    this.pricingTimeout = setTimeout(this.updateOffer.bind(this, this.parcelForm.value), 1000);
  }

  private updateOffer(parcelFormSnapshot: any) {
    this.updatingPrices = true;
    this.apiService
      .getOffer$(this.mapParcelFormToPricingRequest(parcelFormSnapshot))
      .subscribe({
        next: (res: any) => {
          clearTimeout(this.pricingTimeout);
          this.updatingPrices = false;
          this.selectedOperator = {};
          this.formService.setOperatorSelect({});
          this.pricing = res;
          this.pricingError = false;
          this._pricing$.next(this.mapPricingResponseToParcelOperator(res));
        },
        error: (err) => {
          clearTimeout(this.pricingTimeout);
          this.updatingPrices = false;
          this.pricingError = true;
        }
      });
  }

  get currentOfferPrice() : Price|undefined {
    return this.currentOffer?.price;
  }

  get currentOffer(): undefined|PriceResponse {
    if (!this.selectedOperator.operator || !this.selectedOperator.deliveryType) {
      return undefined;
    }
    return this.findOffer(this.selectedOperator.operator, this.selectedOperator.deliveryType);
  }

  findOffer(operator: string, deliveryType: DeliveryType): undefined|PriceResponse {
    return this.pricing?.find(item =>
      item.deliveryType === deliveryType &&
      item.operatorName === operator);
  }

  selectService(operator: string, deliveryType: DeliveryType) {
    if (!this.isOperatorOfferEnabled(operator, deliveryType)) return;

    this.selectedOperator = { "operator": operator.toUpperCase(), "deliveryType": deliveryType };
    this.selectedOperator.posId = this.formService.getStoredPoint(operator, deliveryType);

    const widgetWidth = (this.isMobile ? '95%' : '720px');

    this.dialog.open(this.dialogRef, {
      panelClass: 'bp-widget-container',
      width: widgetWidth,
      maxWidth: widgetWidth
    });

    if (this.mapLoaded) {
      this.openMap(this.selectedOperator, this.formService);
    } else {
      this.loadMap(this.selectedOperator);
    }
  }

  private mapParcelFormToPricingRequest(parcelFormValue: any) : PriceRequest {
    return {
      parcels:
        [
          {
            dimensions: {
              height: parcelFormValue['dimensions']['height'],
              length: parcelFormValue['dimensions']['length'],
              width: parcelFormValue['dimensions']['width'],
              weight: parcelFormValue['dimensions']['weight']
            },
            insuranceValue: parcelFormValue['insuranceValue']
          }
        ],
        additionalServices: this.formService.validationAdditionalServices
      } as PriceRequest;
  }

  private mapPricingResponseToParcelOperator(pricing: PriceResponse[]): ParcelOperator[] {
    let deliveryOptions: ParcelOperator[] = [];
    pricing
      .filter(operatorPrice => this.allowedDeliveryTypes.includes(operatorPrice.deliveryType))
      .forEach(operatorPrice => {
      deliveryOptions.push({
        operatorName: operatorPrice.operatorName,
        deliveryType: operatorPrice.deliveryType,
        price: operatorPrice.price,
        available: operatorPrice.available,
        unavailableReasons: operatorPrice.unavailableReasons?.map(reason => reason.messageCode)
      })
    });

    return deliveryOptions;
  }

  private loadMap(operator: OperatorSelection) {
    this.scriptService.loadStyles(
      `${this.mapUrl}/main.css`
    ).onload = () => {
      const mapScript = this.scriptService.loadScript(
        `${this.mapUrl}/main.js`
      );

      mapScript.onload = () => {
        if (!this.mapLoaded) {
          this.openMap(operator, this.formService);
        }
        this.mapLoaded = true;
      }
    };
  }

  get configurationReady(): boolean {
    return this.configReady && this.formsReady;
  }

  private openMap(operator: OperatorSelection, service: ReturnFormService) {

    const mapOptions = {
      callback: function(point: POSModel) {
        service.setOperatorSelect({operator: point.operator, deliveryType: operator.deliveryType, posId: point.code});
      },
      posType: 'POSTING',
      hideFilters: false,
      codeSearch: true,
      operators: [{ operator: operator.operator, price: null }],
    } as MapOptions;

    if (!!operator.posId) {
      mapOptions.selectedPos = { code: operator.posId, operator: operator.operator! };
    }
    window.BPWidget.init(document.getElementById('bpWidget'), mapOptions);
  }

  scrollToElement() {
    // 64px = 4rem higher than the offsetTop of the element itself - for better visibility
    this.windowService.scrollToFirstElement('form .error, form-errors .error', 64);
  }

  onSubmit() {
    if (this.mainForm.valid) {
      let formModel = this.formService.remapFormToModel();
      formModel.price = this.currentOfferPrice;
      this.isOrderProcessing = true;
      this.orderProcessingError = false;
      this.apiService
        .adviceOrder$(formModel)
        .subscribe({
          next: (res: Order) => {
            if (res && res.paymentUrl && this.platformService.isBrowser()) {
              window.location.href = res.paymentUrl;
            }
          },
          error: (err) => {
            this.isOrderProcessing = false;
            this.orderProcessingError = true;
            this.processingMessages = [
              {
                type: MessageType.ERROR,
                code: 'FORM.ADVICE_ERROR'
              }
            ]
          }
        });
    } else {
      this.formService.markFormAsTouched(this.mainForm);
      this.processingMessages = [
        {
          type: MessageType.ERROR,
          code: 'FORM.INVALID_FORM',
          actionText: 'FORM.CORRECT_FORM',
          action: this.scrollToElement.bind(this),
        }
      ]
    }
  }

}
