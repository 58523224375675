import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  ViewChild
} from '@angular/core';

import { AbstractControlComponent } from '../abstract-control-component';

@Component({
  selector: 'st-input',
  templateUrl: './input.component.html',
  styleUrls: [
    '../abstract-control-component.scss',
    './input.component.scss',
  ]
})
export class InputComponent extends AbstractControlComponent<string> {
  @Input() label: string = '';
  @Input() isNumber = false;
  @Input() min!: number;
  @Input() max!: number;
  @Input() autocomplete = 'on';
  @Input() placeholder = '';
  @Input() showError = true;
  @Input() integersOnly = false;
  @Input() dataTest!: string;
  @Input() hintText!: string
  @Input() backdropText!: string;


  constructor() {
    super();
  }

  @ViewChild('input', {static: true}) input!: ElementRef<HTMLInputElement>;

  override ngOnInit() {
    super.ngOnInit();
    if (this.autofocus === '' && this.formControl.enabled) {
      this.input.nativeElement.focus();
    }
  }
}
