import { Component, Input } from '@angular/core';

import { AbstractControlComponent } from '../abstract-control-component';

@Component({
  selector: 'st-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent extends AbstractControlComponent<boolean> {

  @Input() dataTest!: string;
  @Input() showError = true;
  @Input() errorLabel = '*';

  constructor() { 
    super();
  }

  override ngOnInit(): void {
    if (this.formControl?.enabled && this.autofocus === '') {
      this.isFocused = true;
    }
  }

  onChange(event: Event) {
    this.formControl.markAsTouched();
    this.change.next(this.formControl.value);
  }

  override get isErrorVisible() {
    return this.showError && this.formControl.touched && this.isRequired && !this.formControl.valid;
  }

}
