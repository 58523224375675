import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[domChange]'
})
export class DomChangeDirective implements OnInit {
  constructor(
    private elementRef: ElementRef) {
  }

  ngOnInit() {
    this.registerDomChangeEvent(this.elementRef.nativeElement);
  }

  registerDomChangeEvent(observedElement: HTMLElement) {
    const observer = new MutationObserver(list => {
      const event = new CustomEvent('dom-changed', {
        detail: list,
        bubbles: true
      });
      observedElement.dispatchEvent(event);
    });

    observer.observe(observedElement, {
      attributes: true, 
      childList: true, 
      subtree: true
    });
  }
}
