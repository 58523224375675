import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import * as Shared from '.';
import { FormsModule } from '../forms/forms.module';

const SHARED_IMPORTS = [
  CommonModule,
  FormsModule,
  HttpClientModule,
  TranslateModule,
];
const SHARED_DECLARATIONS = [
  Shared.AppHeaderComponent,
  Shared.AppFooterComponent,
  Shared.OperatorLogoUrlPipe,
  Shared.DomChangeDirective
]

@NgModule({
  declarations: [
    SHARED_DECLARATIONS
  ],
  imports: [
    SHARED_IMPORTS
  ],
  exports: [
    SHARED_IMPORTS,
    SHARED_DECLARATIONS
  ]
})
export class SharedModule { }
