import { Component, OnInit } from '@angular/core';
import { catchError, tap } from 'rxjs';
import { WebSocketService } from 'src/app/service';
import { OrderStatus } from 'src/app/shared/model/order.model';

@Component({
  selector: 'progressbar',
  templateUrl: './progressbar.component.html',
  styleUrls: ['./progressbar.component.scss']
})
export class ProgressbarComponent implements OnInit {

  value: string = "0%";
  error = false;

  constructor(
    private websocketService: WebSocketService
  ) { }

  ngOnInit(): void {
    this.websocketService.orderStatuses$.pipe(
      catchError(error => { throw error }),
      tap(data => {
        const currentOrderStatus = data.status;
        this.getProgress(currentOrderStatus);
      })
    ).subscribe();
  }

  getProgress(status: OrderStatus|undefined) {
    switch (status) {
      case OrderStatus.NEW: 
      case OrderStatus.SAVED: 
      case OrderStatus.PROCESSING: 
             this.error = false;
             this.value = "0%";
             break;
      case OrderStatus.WAITING_FOR_PAYMENT:
            this.error = false;
            this.value = "25%";
            break;
      case OrderStatus.PAYMENT_CONFIRMED:
            this.error = false;
            this.value = "50%";
            break;
      case OrderStatus.ADVISING:
            this.error = false;
            this.value = "75%";
            break;
      case OrderStatus.READY_TO_SEND:
            this.error = false;
            this.value = "100%";
            break;
      case OrderStatus.ERROR:
      case OrderStatus.PAYMENT_REJECTED:
            this.value = "100%";
            this.error = true;
    }
  }

}
