import { Component, OnInit } from '@angular/core';
import { ConfigService } from "../../../service";

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent implements OnInit {
  whatIsPath = "czym-jest-bliska-paczka";

  constructor(public configService: ConfigService) { }

  ngOnInit(): void {
  }

}
