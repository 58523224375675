import { Inject, Injectable, Optional } from "@angular/core";
import { Request, Response } from 'express';
import { DOCUMENT } from '@angular/common';
import { PlatformService } from "./platform.service";
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens'

@Injectable({
  providedIn: 'root'
})
export class CookieService {
  private DAYS_TO_COOKIE_EXPIRE = 10;
  private MILLISECONDS_IN_A_DAY = 24 * 60 * 60 * 1000;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Optional() @Inject(REQUEST) private request: Request,
    @Optional() @Inject(RESPONSE) private response: Response,
    private platformService: PlatformService,
    ) {}

  setCookie(name: string, value: string): void {
    const expirationDate = new Date(Date.now() + this.DAYS_TO_COOKIE_EXPIRE * this.MILLISECONDS_IN_A_DAY);

    if (this.platformService.isBrowser()) {
      const expires = "expires=" + expirationDate;
      this.document.cookie = `${name}=${value};${expires};path=/`;
    } else if (this.response) {
      this.response.cookie(name, value, { expires: expirationDate, path: '/' });
    }
  }

  getCookie(name: string): string | null {
    if (this.platformService.isBrowser()) {
      const nameEQ = `${name}=`;
      return this.document.cookie.split(';')
        .map(c => c.trim())
        .find(c => c.startsWith(nameEQ))?.substring(nameEQ.length) || null;
    } else if (this.request) {
      const cookies = this.request.headers.cookie?.split(';') || [];
      return cookies.map(c => c.trim().split('='))
        .find(([cookieName]) => cookieName === name)?.[1] || null;
    }
    return null;
  }
}
