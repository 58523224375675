import {
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';

import { AbstractControlComponent } from '../abstract-control-component';

@Component({
  selector: 'st-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: [
    '../abstract-control-component.scss',
    './textarea.component.scss',
  ],
})
export class TextareaComponent extends AbstractControlComponent<string> {
  @Input() label!: string;
  @Input() minlength!: number;
  @Input() placeholder: string = '';
  @Input() showError = true;
  @Input() showCounter = false;
  @Input() rows: number = 5;
  @Input() extraContent: string = '';

  @ViewChild('input', { static: true })
  input!: ElementRef<HTMLInputElement>;

  override ngOnInit() {
    super.ngOnInit();
    if (this.autofocus === '' && this.formControl.enabled) {
      this.input.nativeElement.focus();
    }
  }
}
