<label>
  <div
    [class]="label ? 'label' : 'empty-label'"
    [title]="label ? label : ''"
  >
    <ng-container *ngIf="label && isRequired">
      <span class="asterisk">*</span>
    </ng-container>
    <span>{{ label ? label : '' }}</span>
  </div>

  <div class="input-container">
    <input
      *ngIf="isNumber; else textInput"
      #input
      class="focusable input-xshort"
      type="text"
      number-value
      [integersOnly]="integersOnly"
      [name]="name"
      form-control
      [min]="min"
      [formControl]="formControl"
      [maxlength]="maxlength"
      [placeholder]="placeholder"
      (focus)="onFocus($event)"
      (blur)="onBlur($event)"
      attr.data-test="{{ dataTest }}"
    >
    <ng-template #textInput>
      <input
        #input
        class="focusable input-xshort"
        type="text"
        [name]="name"
        form-control
        [formControl]="formControl"
        [maxlength]="maxlength"
        [placeholder]="placeholder"
        (focus)="onFocus($event)"
        (blur)="onBlur($event)"
        attr.data-test="{{ dataTest }}"
      >
    </ng-template>
    <span 
      class="backdrop-text"
      *ngIf="backdropText"
    >{{ backdropText }}</span>
  </div>

  <div
    *ngIf="hintText"
    class="hint-text"
  >
    {{ hintText }}
  </div>

  <form-errors
    *ngIf="isErrorVisible && showError"
    [control]="formControl"
    [label]="label"
  ></form-errors>

</label>
