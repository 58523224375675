import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';

@Component({
  selector: 'parcel-dimensions',
  templateUrl: './parcel-dimensions.component.html',
  styleUrls: ['./parcel-dimensions.component.scss']
})
export class ParcelDimensionsComponent implements OnInit {

  @Input('form') formGroup!: FormGroup;

  @Output() changeEvent = new EventEmitter<string>();

  itemsFormControls: any = null;

  constructor(
    public controlContainer: ControlContainer
  ) { }

  ngOnInit(): void {
    if (!this.itemsFormControls) {
      this.itemsFormControls = this.formGroup.controls;
    }
  }

  onChangeCallback(event?: Event) {
    this.changeEvent.emit('change');
  }

}
