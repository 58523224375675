import { Component, Input } from '@angular/core';
import { Message, MessageType } from 'src/app/shared/model/message';

@Component({
  selector: 'message-panel',
  templateUrl: './message-panel.component.html',
  styleUrls: ['./message-panel.component.scss']
})
export class MessagePanelComponent {

  @Input() messages!: Message[];

  constructor() { }

  messageAction(message: Message): void {
    message.action && message.action();
  }

  getPanelClass() : string {
    if (this.messages.some(m => m.type == MessageType.ERROR)) {
      return MessageType.ERROR.toString();
    } 
    if (this.messages.some(m => m.type == MessageType.WARNING)) {
      return MessageType.WARNING.toString();
    }
    return '';
  }

}
