import { DeliveryType, OrderAdditionalService, Price } from "./price.model";

export enum OrderStatus {
    NEW = "NEW",
    SAVED = "SAVED",
    ADVISING = "ADVISING",
    PROCESSING = "PROCESSING",
    READY_TO_SEND = "READY_TO_SEND",
    WAITING_FOR_PAYMENT = "WAITING_FOR_PAYMENT",
    PAYMENT_CONFIRMED = "PAYMENT_CONFIRMED",
    PAYMENT_REJECTED = "PAYMENT_REJECTED",
    ERROR = "ERROR"
}

export interface OperatorStatusChanges {
    updatedAt: string;
    status: string;
    description: string;
    place: string;
}

export interface OrderStatusChange {
    dateTime: string;
    status: string;
    reason?: string;
    operatorStatusChanges?: OperatorStatusChanges[];
}

export interface ProductItem {
    itemName: string,
    itemCount: number,
    itemPrice: number
}

export interface Order {
    status?: OrderStatus;
    number?: string;
    reference?: string;
    creationDate?: string;
    adviceDate?: string;
    deliveryType?: DeliveryType;
    additionalServices: OrderAdditionalService[];

    trackingNumber?: string;

    senderName?: string;
    senderFirstName?: string;
    senderLastName?: string;
    senderCompanyName?: string;
    senderPhoneNumber: string;
    senderEmail: string;
    senderStreet: string;
    senderBuildingNumber: string;
    senderFlatNumber?: string;
    senderPostCode: string;
    senderCity: string;

    receiverName?: string;
    receiverFirstName?: string;
    receiverLastName?: string;
    receiverCompanyName?: string;
    receiverPhoneNumber: string;
    receiverEmail: string;
    receiverStreet: string;
    receiverBuildingNumber: string;
    receiverFlatNumber?: string;
    receiverPostCode: string;
    receiverCity: string;

    operatorName: string;
    destinationCode?: string;
    destinationPostCode?: string;
    destinationCity?: string;
    destinationStreet?: string;
    postingCode?: string;
    postingPostCode?: string;
    postingCity?: string;
    postingStreet?: string;

    codValue?: number;
    payoutDate?: string;
    promoCode?: string;
    additionalInformation?: string;

    returnConfiguration?: OrderReturnCofiguration;

    userId?: number;
    brokerId?: number;
    // orderItems: OrderPricelistItem[];
    price?: Price;

    parcels?: any[]

    changes?: OrderStatusChange[];

    // paymentType: PaymentType;
    // paymentProvider: PaymentProvider;
    paymentUrl?: string;

    business?: boolean;
    adviceSource?: string;
    creationSource?: string;
    trackingReturnUrl: URL;
}

export interface OrderReturnCofiguration {
	returnShopOrderNumber: string,
	returnReason: string,
	returnReasonAdditionalInfo?: string,
	returnItems: ProductItem[],
}
