import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  catchError,
  Observable,
  ReplaySubject,
  shareReplay,
  tap,
  throwError
} from 'rxjs';
import { Configuration } from '../shared/model/configuration.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  _config$: ReplaySubject<Configuration> = new ReplaySubject<Configuration>(1);
  config$: Observable<Configuration> = this._config$.asObservable().pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true
    })
  );

  constructor(private httpClient: HttpClient) {
    this.loadConfig().subscribe();
   }

  private loadConfig(): Observable<Configuration> {

    return this.httpClient.get<Configuration>('/assets/config/config.json')
      .pipe(
        tap(res => {
          this._config$.next(res);
        }),
        catchError(err => {
          return throwError(() => { err });
        })
      );
  }
}
