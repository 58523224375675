import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'generic-error',
  templateUrl: './generic-error.component.html',
  styleUrls: ['./generic-error.component.scss']
})
export class GenericErrorComponent implements OnInit {

  @Input() header!: string;
  @Input() message!: string;

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    if (this.route.snapshot.data['header']) {
      this.header = this.route.snapshot.data['header'];
    }
    if (this.route.snapshot.data['message']) {
      this.message = this.route.snapshot.data['message'];
    }
  }

}
