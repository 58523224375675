import { POSId } from "./pos.model"

export interface MapOptions {
    callback?: any, 
    posType?: string,
    codeSearch?: boolean,
    hideFilters?: boolean,
    operators: [OperatorsMapOptions],
    selectedPos: POSId,
    alias?: string
}

interface OperatorsMapOptions {
    operator: string,
    price?: any | undefined | null
}
