import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as _ from 'lodash';

export class CustomValidators {
  static bpmaxlength(ppoMaxLength: number): ValidatorFn | null {
    if (_.isNil(ppoMaxLength)) {
      return null;
    }

    return (control: AbstractControl) => {
      const value = _.toString(control.value).trim();

      if (value.length > ppoMaxLength) {
        return { ppoMaxLength };
      }

      return null;
    };
  }

  static number(control: AbstractControl) {
    const pattern = /^[-]?\d+([\.,]?\d+)?$/;

    if (control.value && typeof control.value === 'string' && !control.value.match(pattern)) {
      return { number: true };
    }
    return null;
  }

  static postcode(control: AbstractControl) {
    const pattern = /^\d{2}\-\d{3}$/;

    if (control.value && typeof control.value === 'string' && !control.value.match(pattern)) {
      return { postCode: true };
    }
    return null;
  }

  static phone(control: AbstractControl): { [key: string]: boolean } | null {
    const pattern = /^\d{9}$/;

    const value = control.value
      ? control.value.replace(/[^a-zA-Z0-9]*/g, '') : control.value;

    if (value && !value.match(pattern)) {
      return { phone: true };
    }
    return null;
  }

  static specialMobilePhone(control: AbstractControl)
    : { [key: string]: boolean } | null {
    const pattern = /^(5[0137]|6[069]|7[2389]|88)\d{7}$/;

    const value = control.value
      ? control.value.replace(/[^a-zA-Z0-9]*/g, '') : control.value;

    if (!value || !value.match(pattern)) {
      return { mobilePhone: true };
    }
    return null;
  }

  static email(control: AbstractControl) : { [key: string]: boolean } | null {
    const pattern = /^[A-Za-z\d._%+-]+@[A-Za-z0-9.-]+\.[a-zA-Z]{2,}$/;
    const value = _.toString(control.value).trim();

    if (value && !value.match(pattern)) {
      return { email: true };
    }
    return null;
  }

  static nameWithLastname(control: AbstractControl) : { [key: string]: boolean } | null {
    const pattern = /^.+\s.+$/;
    const value = _.toString(control.value).trim();

    if (value && !value.match(pattern)) {
      return { nameWithLastname: true };
    }
    return null;
  }

  static nip(control: AbstractControl) : { [key: string]: boolean } {
    const pattern = /^\d{10}$/;
    const value = control.value
      ? control.value.replace(/[^a-zA-Z0-9]*/g, '') : control.value;

    if (value && !value.match(pattern)) {
      return { nip: true };
    }

    if (value && value.match(pattern)) {
      return CustomValidators.validateChecksum(value);
    }

    return { nip: false };
  }


  private static validateChecksum(value: string) {
    const digits = value.split('').map((item: string) => parseInt(item, 10));
    const checksum = (
      (
        6 * digits[0] + 5 * digits[1] + 7 * digits[2] + 2 * digits[3] +
        3 * digits[4] + 4 * digits[5] + 5 * digits[6] + 6 * digits[7] +
        7 * digits[8]
      )
      % 11
    );

    if (checksum !== digits[9]) {
      return { nip: true };
    }

    return { nip: false };
  }
}
