<div
  id="footer"
  class="footer"
>
  <div class="item-wrapper">
    <a
      class="item"
      [attr.href]="(configService.config$ | async)?.frontUrl + whatIsPath"
      target="_blank"
    >
      <img
        class="item"
        src="/assets/images/alsendo_logo.svg"
        [attr.alt]="'PORTAL_NAME' | translate"
      >
    </a>
  </div>
</div>
