import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'customer-data',
  templateUrl: './customer-data.component.html',
  styleUrls: ['./customer-data.component.scss']
})
export class CustomerDataComponent implements OnInit {

  @Input('translationNamespace') translationNamespace: string = 'CUSTOMER';

  constructor(
    public controlContainer: ControlContainer
  ) {
  }

  ngOnInit(): void {
  }

  get namespacedCustomerNameField() : string {
    return 'FORM.ADDRESS_DATA.' + this.translationNamespace + '.NAME';
  }

  getNamespacedCustomerFieldName(fieldName: string) : string {
    return this.translationNamespace.toLowerCase() + fieldName;
  }

  get customerFormControls() : any {
    let control = this.controlContainer.control as FormGroup;
    return control.controls;
  }
}
