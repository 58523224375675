<div class="row">
  <st-input
    [control]="itemsFormControls.width"
    label="{{ 'FORM.RETURN_PARCEL.DIMENSIONS_LABEL' | translate }}"
    isRequired="true"
    [isNumber]=true
    [integersOnly]=true
    [min]="1"
    [maxlength]=7
    backdropText="cm"
    name="parcelWidth"
    data-test="parcelWidth"
    class="col-5"
    (change)="onChangeCallback()"
  >
  </st-input>
  <st-input
    [control]="itemsFormControls.length"
    label=""
    isRequired="true"
    [isNumber]=true
    [integersOnly]=true
    [min]="1"
    [maxlength]=7
    backdropText="cm"
    name="parcelLength"
    data-test="parcelLength"
    class="col-5"
    (change)="onChangeCallback()"
  >
  </st-input>
  <st-input
    [control]="itemsFormControls.height"
    label=""
    isRequired="true"
    [isNumber]=true
    [integersOnly]=true
    [min]="1"
    [maxlength]=7
    backdropText="cm"
    name="parcelHeight"
    data-test="parcelHeight"
    class="col-5"
    (change)="onChangeCallback()"
  >
  </st-input>
</div>
<div class="row">
  <st-input
    [control]="itemsFormControls.weight"
    label="{{ 'FORM.RETURN_PARCEL.WEIGHT_LABEL' | translate }}"
    isRequired="true"
    [isNumber]=true
    [min]="0.1"
    [maxlength]=7
    backdropText="kg"
    name="parcelWeight"
    data-test="parcelWeight"
    class="col-5"
    (change)="onChangeCallback()"
  >
  </st-input>
</div>
