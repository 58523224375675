import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormArray, FormControl, FormGroup } from '@angular/forms';
import { ReturnFormService } from 'src/app/service/return-form.service';

@Component({
  selector: 'product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss']
})
export class ProductItemComponent implements OnInit {

  itemsFormControls: any = null;
  @Input('form') formGroup!: FormGroup;
  @Input('cancellable') cancellable: boolean = true;
  @Input('itemId') itemId: number = 0;

  constructor(
    public controlContainer: ControlContainer,
    private returnFormService: ReturnFormService
  ) {
  }

  ngOnInit(): void {
    if (!this.itemsFormControls) {
      this.itemsFormControls = this.formGroup.controls;
    }
  }

  get formControls() : FormArray {
    return this.controlContainer.control as FormArray
  }

  deleteItem(id: number) {
    this.returnFormService.deleteProductItem(id);
  }

}
