import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import * as Forms from '.';

const COMPONENTS = [
  Forms.FormErrorsComponent,
  Forms.InputComponent,
  Forms.SelectComponent,
  Forms.TextareaComponent,
  Forms.CheckboxComponent,
  Forms.NumberValueAccessorDirective
];

@NgModule({
  declarations: COMPONENTS,
  exports: COMPONENTS,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule
  ]
})
export class FormsModule { }
