<label>
  <div
    *ngIf="label"
    class="label"
    [title]="label"
  >
    {{ label }}
  </div>
  <div class="select-container">
    <select
      #select
      class="input-xshort"
      [name]="name"
      [formControl]="formControl"
    >
      <option
        *ngIf="placeholder"
        value
        disabled
        selected
      >
        {{ placeholder }}
      </option>
      <ng-container *ngFor="let option of optionsAsObjects">
        <option
          [value]="option.value"
        >
          {{ option.description ? option.description : (translationPrefix + option.value | translate) }}
        </option>
      </ng-container>
      <ng-container *ngFor="let option of options">
        <option
          [value]="option"
        >
          {{ option | translate }}
        </option>
      </ng-container>
      <option
        *ngIf="!placeholder"
        class="hidden"
        value
        selected
      >
      </option>
      <option
        class="hidden"
        selected="selected"
      >
        {{ formControl.value }}
      </option>
    </select>
  </div>

  <form-errors
    *ngIf="isErrorVisible && showError"
    [control]="formControl"
    [label]="label"
  ></form-errors>

</label>
