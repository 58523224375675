import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ReturnFormService } from 'src/app/service/return-form.service';


@Component({
  selector: 'product-items',
  templateUrl: './product-items.component.html',
  styleUrls: ['./product-items.component.scss']
})
export class ProductItemsComponent implements OnInit {

  @Input('form') formArray!: FormArray;

  constructor(
    public controlContainer: ControlContainer,
    private returnFormService: ReturnFormService
  ) {
  }

  ngOnInit(): void {
  }

  getItemFormControls(id: number) : FormGroup {
    return this.formArray.controls[id] as FormGroup;
  }

  addProductItem() {
    this.returnFormService.addProductItem();
  }

}
