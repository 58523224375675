import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import * as Partials from '.';
import { FormsModule } from '../forms/forms.module';
import { SharedModule } from '../shared/shared.module';

const IMPORTS = [
  CommonModule,
  FormsModule,
  SharedModule,
  HttpClientModule,
  TranslateModule
];
const DECLARATIONS = [
  Partials.CustomerDataComponent,
  Partials.DimmerComponent,
  Partials.MessagePanelComponent,
  Partials.OperatorSelectComponent,
  Partials.ParcelDimensionsComponent,
  Partials.ProductItemComponent,
  Partials.ProductItemsComponent,
  Partials.ProgressbarComponent
]

@NgModule({
  declarations: [
    DECLARATIONS,
  ],
  imports: [
    IMPORTS
  ],
  exports: [
    IMPORTS,
    DECLARATIONS
  ]
})
export class PartialsModule { }
