<div class="main-header-wrapper">
  <div class="main-header-container">
    <h1 class="main-header">{{ 'FORM.MAIN_HEADER' | translate }}</h1>
    <p class="sub-header">{{ 'FORM.MAIN_HEADER_SUBTEXT' | translate }}</p>
  </div>
  <img
    class="bp-logo-mini"
    src="/assets/images/alsendo_logo.svg"
    [attr.alt]="'PORTAL_NAME' | translate"
  >
</div>
