import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  template: 'input',
})
export class AbstractControlComponent<T> implements OnInit {
  @Input('form') formGroup!: FormGroup;
  @Input('control') formControl!: FormControl;
  @Input() name = '';
  @Input() autofocus: any = false;
  @Input() maxlength!: number;
  @Output() blur = new EventEmitter<Event>();
  @Output() focus = new EventEmitter<Event>();
  @Output() change = new EventEmitter<T>();
  @HostBinding('class.focused') isFocused = false;

  constructor() { }

  ngOnInit() {
    if (!this.formControl) {
      this.formControl = this.formGroup.controls[this.name] as FormControl;
      if (!this.formControl) {
        console.error(`Unknown field: ${this.name}`);
      }
    }
    if (this.autofocus === '' && this.formControl.enabled) {
      this.isFocused = true;
    }
  }

  @HostBinding('class.invalid')
  get isInvalid() {
    return this.formControl.invalid;
  }

  @HostBinding('class.touched')
  get isTouched() {
    return this.formControl.touched;
  }

  @HostBinding('class.filled')
  get isFilled() {
    return !!this.formControl.value;
  }

  get isRequired() {
    return !!this.formControl.validator
      && !!this.formControl.validator(Validators.required as any);
  }

  get isErrorVisible() {
    return this.isTouched && this.isInvalid;
  }

  get isApprovedVisible() {
    return this.isTouched && !this.isInvalid;
  }

  onFocus(event: Event) {
    if (!this.isFocused) {
      this.isFocused = true;
      this.focus.next(event);
    }
  }

  onBlur(event: Event) {
    if (this.isFocused) {
      this.isFocused = false;
      this.blur.next(event);
    }
  }
}
