import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root'
})
export class WindowService {
  isMobile$: Observable<boolean>;

  private _isMobile$ = new ReplaySubject<boolean>(1);

  constructor(
    private platformService: PlatformService
  ) {
    this.isMobile$ = this._isMobile$.asObservable();
    if (this.platformService.isBrowser()) {
      window.addEventListener('resize', () => {
        this.checkResolution();
      });
      this.checkResolution();
    }
  }

  checkResolution() {
    if (this.platformService.isBrowser()) {
      this._isMobile$.next(this.isMobile);
    }
  }

  scrollToFirstElement(elementDef: string, safeMargin: number = 0) {
    if (this.platformService.isBrowser() && !!elementDef) {
      let highestElemTop: number = window.scrollY + window.innerHeight;
      document.querySelectorAll(elementDef).forEach((element) => {
        if (highestElemTop > element.getBoundingClientRect().top) {
          highestElemTop = element.getBoundingClientRect().top;
        }
      });
      window.scrollTo({
        top: window.scrollY + highestElemTop - safeMargin,
        behavior: "smooth"
      });
    }
  }

  get isMobile() {
    if (this.platformService.isBrowser()) {
      return window.innerWidth <= 768;
    }
    return false;
  }
}
