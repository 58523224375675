<label>
  <div
    *ngIf="label"
    class="label"
    [title]="label"
  >
    {{ label }}
  </div>

  <div class="input-container">
    <textarea
      class="focusable input-xshort"
      [maxlength]="maxlength"
      form-control
      [name]="name"
      [formControl]="formControl"
      [minlength]="minlength"
      [rows]="rows"
      [placeholder]="placeholder"
      (focus)="onFocus($event)"
      (blur)="onBlur($event)"
    ></textarea>
    <div class="extra-content">
      <div *ngIf="extraContent">{{extraContent}}</div>
      <div *ngIf="showCounter && formControl.value">
        {{maxlength - formControl.value.length}}/{{maxlength}}
      </div>
    </div>
  </div>

  <form-errors
    *ngIf="isErrorVisible && showError"
    [control]="formControl"
    [label]="label"
  ></form-errors>

</label>
