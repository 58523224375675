import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localePl from '@angular/common/locales/pl';
import { LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { AuthGuard } from './core/auth.guard';
import { FormsModule } from './forms/forms.module';
import { GenericErrorComponent } from './generic-error/generic-error.component';
import { MainComponent } from './main/main.component';
import { MainHeaderComponent } from "./main/main-header/main-header.component";
import { PartialsModule } from './partials/partials.module';
import { ConfigService } from './service';
import { AuthService } from './service/auth.service';
import { ServiceModule } from './service/service.module';
import { SharedModule } from './shared/shared.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

registerLocaleData(localePl, 'pl');
@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    MainHeaderComponent,
    GenericErrorComponent,
    ConfirmationComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'enterprise-returns' }),
    AppRoutingModule,
    FormsModule,
    MatDialogModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'pl',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ServiceModule,
    SharedModule,
    PartialsModule,
    BrowserAnimationsModule
  ],
  providers: [{
    provide: LOCALE_ID,
    useValue: 'pl'
  },
  AuthGuard,
  AuthService,
  ConfigService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
