import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'form-errors',
  templateUrl: './form-errors.component.html',
  styleUrls: ['./form-errors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormErrorsComponent implements OnInit, OnDestroy {

  @Input() label: string = '';
  @Input('control') set formControl(formControl: FormControl) {
    this._formControl = formControl;

    this.clearSubscription();
    this.computeErrors();
    this.subscription.add(this.formControl.statusChanges.subscribe(
      () => this.computeErrors(),
    ));
  }

  @Input('group') set formGroup(formGroup: FormGroup) {
    this._formGroup = formGroup;

    this.clearSubscription();
    this.computeGroupErrors();
    this.subscription.add(this.formGroup.statusChanges.subscribe(
      () => this.computeGroupErrors(),
    ));
  }

  get formControl() {
    return this._formControl;
  }

  get formGroup() {
    return this._formGroup;
  }

  subscription = new Subscription();

  errors: any[] = [];
  private _formControl!: FormControl;
  private _formGroup!: FormGroup;

  constructor(private cdr: ChangeDetectorRef, private translateService: TranslateService) { }

  VALIDATION_MESSAGES = {
    required: () =>
      this.translateService.instant('VALIDATION_MESSAGES.required'),
    email: () =>
      this.translateService.instant('VALIDATION_MESSAGES.email'),
    min: (data: { min: number }) =>
      this.translateService.instant('VALIDATION_MESSAGES.min') + data.min,
    max: (data: { parameter: number }) =>
      this.translateService.instant('VALIDATION_MESSAGES.max') + data.parameter,
    ppoMaxLength: (data: number) =>
      this.translateService.instant('VALIDATION_MESSAGES.ppoMaxLength') + data,
    nameWithLastname: () => this.translateService.instant('VALIDATION_MESSAGES.nameWithLastname'),
    number: () => this.translateService.instant('VALIDATION_MESSAGES.number'),
    postCode: () => this.translateService.instant('VALIDATION_MESSAGES.postCode'),
    phone: () => this.translateService.instant('VALIDATION_MESSAGES.phone'),
    mobilePhone: () => this.translateService.instant('VALIDATION_MESSAGES.mobilePhone')

  } as any;

  ngOnInit() { }

  ngOnDestroy() {
    this.clearSubscription();
  }

  clearSubscription() {
    this.subscription.unsubscribe();
  }

  computeErrors() {
    if (this.formControl.touched && this.formControl.errors) {
      this.errors = Object.keys(this.formControl.errors).map(e => {
        if (!!this.formControl.errors && this.formControl.errors[e]) {
          const error = this.formControl.errors;
          return this.VALIDATION_MESSAGES[e] ? this.VALIDATION_MESSAGES[e](error[e]) : error;
        }
      });
    } else {
      this.errors = [];
    }
    this.cdr.markForCheck();
  }

  computeGroupErrors() {
    let noError = true;

    Object.keys(this.formGroup.controls).forEach(key => {
      const abstractControl = this.formGroup.controls[key];

      if (abstractControl.touched && abstractControl.errors) {
        noError = false;
      }
    });
    if (noError) {
      this.errors = [];
    } else {
      this.errors = ['Popraw zaznaczone pola'];
      this.cdr.markForCheck();
    }
  }
}
