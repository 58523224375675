import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'operatorLogoUrl' })
export class OperatorLogoUrlPipe implements PipeTransform {
  constructor() { }
  transform(operator: string) {
    switch (operator.toUpperCase()) {
      case 'RUCH':
        return '/assets/images/operators/ORLEN.png';
        break;
      default:
        return '/assets/images/operators/' + operator.toUpperCase() + '.png';
    }
  }
}
