import { Injectable, OnDestroy } from '@angular/core';
import { PlatformService } from './platform.service';
import { ConfigService } from './config.service';
import { Subscription } from 'rxjs';

declare global {
  interface Window {
    dataLayer: any[];
    gtag: (command: string, ...args: any[]) => void;
  }
}

@Injectable()
export class GtmService implements OnDestroy {
  private subscription = new Subscription();

  constructor(
    private platformService: PlatformService,
    private configService: ConfigService
  ) { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  init() {
   this.subscription = this.configService.config$.subscribe(res => {
      if (res.gtmId) {
        this.addGtmToDom(res.gtmId);
      }
    })
  }

  pushEvent(event: any) {
    this.platformService.inBrowser(() => {
      if (window.dataLayer) {
        window.dataLayer.push(event);
      }
    });
  }

  private addGtmToDom(gtmId: string) {
    const gtagScript = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${gtmId}`;
    document.head.appendChild(gtagScript);

    window.dataLayer = window.dataLayer || [];
    function gtag(arg: any, arg2: any){window.dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', gtmId);
  }
  
}
