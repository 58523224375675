
import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot } from "@angular/router";
import { catchError, map, Observable, of, shareReplay, Subject, tap } from "rxjs";
import { environment } from "../../environments/environment";

import { SelectOption } from "../forms";
import { ApiConfig, ParcelOperator } from "../shared/model/api-configuration.model";
import { CountryScopedOffer, DeliveryType } from "../shared/model/price.model";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userConfig!: ApiConfig;
  _userConfig$: Subject<ApiConfig> = new Subject<ApiConfig>();
  userConfig$: Observable<ApiConfig> = this._userConfig$.asObservable().pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true
    })
  );

  allowedDeliveryTypes: DeliveryType[];

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute
  ) {
    this.allowedDeliveryTypes = environment.allowedDeliveryTypes as unknown as DeliveryType[];
  }

  public getUserConfig$(snapshot: ActivatedRouteSnapshot|null): Observable<ApiConfig> {
    if (!this.userConfig) {
      this.userConfig$ = this.parseToken$(!!snapshot ? snapshot : this.route.snapshot);
    }
    return this.userConfig$;
  }

  parseToken$(snapshot: ActivatedRouteSnapshot): Observable<ApiConfig> {
    const token = snapshot.queryParamMap.get('token') || this.apiService.userToken;
    if (token) {
      this.apiService.userToken = token;
      return this.apiService
        .authorizeByToken$(token).pipe(
          catchError(() => of({} as ApiConfig)),
          map(v => {
            if (v.offer && v.returnReasons) {
              return {
                deliveryOptions: this.mapOfferToDeliveryOptions(v.offer),
                returnReasons: this.mapReturnReasonsToObject(v.returnReasons)
              } as ApiConfig;
            }
            return v;
          }),
          tap((ret: ApiConfig) => {
            this._userConfig$.next(ret);
            this.userConfig = ret;
          })
        );
    } else {
      return of({} as ApiConfig);
    }
  }

  private mapOfferToDeliveryOptions(offer: CountryScopedOffer[]): ParcelOperator[] {
    return offer
      .flatMap(i => i.priceLists)
      .flatMap(price => ({
        "operatorName": price.operator,
        "deliveryType": price.serviceType
        })
      )
      .filter(it => this.allowedDeliveryTypes
        .find(type => type == it.deliveryType)
      );
  }

  private mapReturnReasonsToObject(enumReasons: string[]): SelectOption[] {
    return enumReasons.map(reason =>
      ({ "value": reason })
    );
  }

}
