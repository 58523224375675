
import { Injectable } from "@angular/core";
import { shareReplay } from "rxjs";
import { Subject } from "rxjs/internal/Subject";
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { environment } from "../../environments/environment";
import { Order, OrderStatus } from "../shared/model/order.model";
import { ApiService } from "./api.service";
import { PlatformService } from "./platform.service";

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {

  private orderTerminatingStatuses = [
    OrderStatus.ERROR,
    OrderStatus.PAYMENT_REJECTED,
    OrderStatus.READY_TO_SEND
  ];

  private socket$!: WebSocketSubject<any>;
  private statusSocketUrl: string;

  private _orderStatuses$: Subject<Order> = new Subject(); // = new BehaviorSubject<any>(null);
  public orderStatuses$ = this._orderStatuses$.asObservable().pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true
    })
  );

  constructor(
    private apiService: ApiService,
    private platformService: PlatformService
  ) {
    this.statusSocketUrl = this.getSocketProxyUrl();
  }

  public connect(): void {
    if (!this.socket$ || this.socket$.closed) {
      this.socket$ = webSocket(this.statusSocketUrl);
      this.socket$.subscribe({
        next: msg => {
          this._orderStatuses$.next(msg);
          if (this.isOrderTerminatingStatus(msg)) {
            this.close();
          }
        },
        error: err => console.log(err),
        complete: () => console.log('complete')
      })
    }
  }

  private isOrderTerminatingStatus(order: Order): boolean {
    if (!order || !order.status) return false;
    return this.orderTerminatingStatuses.some(x => x == order.status);
  }

  sendMessage(message: any) {
    if (this.socket$) {
      const payload = {
        token: this.apiService.userToken,
        message: message
      }
      this.socket$.next(payload);
    }
  }

  close() {
    this.socket$.complete();
  }

  private getSocketProxyUrl(): string {
    let url = new URL(
      '/stream/order/status',
      this.platformService.isBrowser() ? window.location.href : environment.fallbackSocketHostUrl
    );
    url.protocol = url.protocol.replace('https', 'wss');
    url.protocol = url.protocol.replace('http', 'ws');
    return url.href;
  }
}
