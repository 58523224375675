import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { catchError, map, Observable, throwError } from "rxjs";
import { ApiConfig } from "../shared";
import { PriceRequest, PriceResponse } from "../shared/model/price.model";
import { Order } from "../shared/model/order.model";
import { CookieService } from "./cookie.service";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private API_TOKEN_KEY = 'enterpriseReturnsUserToken';

  private _userToken: string|null = null;

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService
  ) {
  }

  public set userToken(token: string|null) {
    if (!token) return;
    this.cookieService.setCookie(this.API_TOKEN_KEY, token);
    this._userToken = token;
  }

  public get userToken(): string|null {
    if (this._userToken) {
      return this._userToken;
    }
    return this.cookieService.getCookie(this.API_TOKEN_KEY);
  }

  public authorizeByToken$(token: string) : Observable<ApiConfig> {
    return this.get$(
      '/context/config'
    ).pipe(
      map(res => ({
        returnReasons: res.returnReasons,
        deliveryOptions: res.deliveryOptions,
        offer: JSON.parse(res.offer)
      }))
    )
  }

  public getOffer$(request: PriceRequest): Observable<any> {
    return this.post$<PriceResponse[]>(
      '/context/pricing',
      JSON.stringify(request)
    );
  }

  public adviceOrder$(request: Order): Observable<Order> {
    return this.put$<Order>(
      '/context/advice',
      JSON.stringify(request)
    );
  }

  public reloadPaymentUrl$(orderNumber: string): Observable<any> {
    return this.put$<String>(
      `/context/order/${orderNumber}/payment`,
      ''
    );
  }

  public getProtocol$(orderNumber: string): Observable<string> {
    return this.getText$(`/context/return-protocol/${orderNumber}`);
  }

  public getWaybill$(orderNumber: string, type: string): Observable<Blob> {
    let headers = new HttpHeaders();
    if (type) {
      headers.append('Accept', type);
    }
    return this.getBlob$('/context/waybill?orderNumbers=' + orderNumber, new HttpParams(), headers).pipe(
    );
  }

  public getBlob$(url: string, params?: HttpParams, headers?: HttpHeaders): Observable<Blob> {
    headers = headers || new HttpHeaders();
    headers = this.appendAuthenticationHeader(headers);

    return this.httpClient.get(url, {
      headers,
      params,
      responseType: 'blob'
    }).pipe(catchError(err => this.handleError(err)));
  }

  public getText$(url: string, params?: HttpParams, headers?: HttpHeaders): Observable<string> {
    headers = headers || new HttpHeaders();
    headers = this.appendAuthenticationHeader(headers);

    return this.httpClient.get(url, {
      headers,
      params,
      responseType: 'text'
    }).pipe(catchError(err => this.handleError(err)));
  }

  private get$(url: string, params?: HttpParams, headers?: HttpHeaders): Observable<any> {
    headers = headers || new HttpHeaders();
    headers = this.appendAuthenticationHeader(headers);

    return this.httpClient.get(url, {
      headers: headers,
      params: params,
      responseType: 'json'
    }).pipe(catchError(err => this.handleError(err)));
  }

  private post$<T>(url: string, body: string, headers?: HttpHeaders): Observable<T> {
    headers = headers || new HttpHeaders();
    headers = this.appendContentType(headers);
    headers = this.appendAuthenticationHeader(headers);

    return this.httpClient.post(url, body, {
      headers: headers,
      responseType: 'json'
    }).pipe(
      catchError(err => this.handleError(err)),
      map(resp => resp as T)
    );

  }
  private put$<T>(url: string, body: string, headers?: HttpHeaders): Observable<T> {
    headers = headers || new HttpHeaders();
    headers = this.appendContentType(headers);
    headers = this.appendAuthenticationHeader(headers);

    return this.httpClient.put(url, body, {
      headers: headers,
      responseType: 'json'
    }).pipe(
      catchError(err => this.handleError(err)),
      map(resp => resp as T)
    );
  }

  private appendAuthenticationHeader(headers: HttpHeaders): HttpHeaders {
    return this.userToken ?
      headers.append('Authorization', `Bearer ${this.userToken}`) :
      headers;
  }

  private appendContentType(headers: HttpHeaders): HttpHeaders {
    return headers.append('Content-Type', 'application/json');
  }

  private handleError(response: HttpErrorResponse) {
    if (response.status >= 500) {
      return throwError(() => {
        errors: [{ messageCode: 'SERVER_ERROR' }]
      });
    }
    if (response.status >= 400) {
      if (response.error && response.error.errors) {
        return throwError(() => {
          errors: response.error.errors
        });
      }
      return throwError(() => {
        errors: [{ messageCode: 'BAD REQUEST' }]
      });
    }
    return throwError(() => {
      errors: [response.error]
    });
  }
}
