import { NgModule } from '@angular/core';
import * as Service from '.';


@NgModule({
  providers: [
    Service.ApiService,
    Service.ConfigService,
    Service.PlatformService,
    Service.ReturnFormService,
    Service.ScriptService,
    Service.WebSocketService,
    Service.WindowService,
    Service.GtmService
  ]
})
export class ServiceModule { }
