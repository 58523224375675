import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class ScriptService {
  private renderer: Renderer2;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  loadScript(src: string, type: string = 'text/javascript', target: ScriptTargets = ScriptTargets.BODY): HTMLScriptElement {
    const script = this.renderer.createElement('script');
    script.type = type;
    script.src = src;
    this.renderer.appendChild(this.document[target], script);

    return script;
  }

  loadStyles(src: string, target: ScriptTargets = ScriptTargets.BODY): HTMLLinkElement {
    const link = this.renderer.createElement('link');
    link.rel = 'stylesheet';
    link.href = src;
    this.renderer.appendChild(this.document[target], link);

    return link;
  }
}

export enum ScriptTargets {
  HEAD = 'head',
  BODY = 'body'
}
