import { Inject, Injector, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { REQUEST } from '@nguniversal/express-engine/tokens';

@Injectable()
export class PlatformService {
  constructor(
    private injector: Injector,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  isServer(): boolean {
    return isPlatformServer(this.platformId);
  }

  inBrowser<T>(callback: () => T, fallback?: T): T | undefined {
    if (this.isBrowser()) {
      return callback();
    }
    return fallback;
  }

  public getCanonicalUrl(): string {
    if (this.isServer()) {
      const request = this.injector.get(REQUEST);
      return 'https://' + request.hostname + request.path;
    }

    if (this.isBrowser()) {
      return 'https://' + window.location.hostname + window.location.pathname;
    }

    return '';
  }

  public getQueryString() {
    if (this.isServer()) {
      const request = this.injector.get(REQUEST);
      return request.query;
    }

    if (this.isBrowser()) {
      return window.location.search;
    }

    return '';
  }
}
