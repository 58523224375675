
<div class="product-item row">
  <div class="col col-15 col-md-14 row">
    <st-input
      [control]="itemsFormControls.description"
      label="{{ 'FORM.RETURN_FORM.RETURNED_PRODUCT_LABEL' | translate }}"
      hintText="{{ 'FORM.RETURN_FORM.RETURNED_PRODUCT_HINT' | translate }}"
      name="product-decription"
      attr.data-test="{{itemId}}-product-description"
      [maxlength]=99
      class="col col-10 col-md-16"
    >
    </st-input>
    <st-input
      [control]="itemsFormControls.unitPrice"
      label="{{ 'FORM.RETURN_FORM.RETURNED_PRODUCT_PRICE_LABEL' | translate }}"
      hintText="{{ 'FORM.RETURN_FORM.RETURNED_PRODUCT_PRICE_HINT' | translate }}"
      backdropText="zł"
      name="product-unit-price"
      attr.data-test="{{itemId}}-product-unit-price"
      [isNumber]=true
      [min]="0.01"
      [maxlength]=10
      class="col col-4 col-md-11"
    >
    </st-input>
    <st-input
      [control]="itemsFormControls.quantity"
      label="{{ 'FORM.RETURN_FORM.RETURNED_PRODUCT_QUANTITY_LABEL' | translate }}"
      name="product-quantity"
      attr.data-test="{{itemId}}-product-quantity"
      [isNumber]=true
      [integersOnly]=true
      [min]="1"
      [maxlength]=3
      class="col col-2 col-md-5"
    >
    </st-input>
  </div>

  <ng-container *ngIf="cancellable; else elseTemplate">
    <div
      aria-roledescription="button"
      attr.data-test="{{itemId}}-delete-item"
      class="delete-item-button col col-1 col-md-2"
      (click)="deleteItem(itemsFormControls.itemId.value)"
    >
      <img class="remove-product-item" src="/assets/icons/delete.svg" />
    </div>
  </ng-container>
  <ng-template #elseTemplate>
      <div class="col col-1 col-md-2"></div>
  </ng-template>

</div>
